import React, {useEffect} from 'react';
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {fetchProjectsDetail} from "../../api/redux/projects";
import {ApiParam} from "../../api/network/apiParams";
import Overview from "../../components/Overview";
import AtaGlance from "../../components/project/AtaGlance";
import FeatureSlider from "../../components/project/FeatureSlider";
import Video from "../../components/project/Video";
import Gallery from "../../components/project/Gallery";
import Location from "../../components/project/Location";
import EnquiryForm from "../../components/project/EnquiryForm";
import InnerBannerBg from "../../components/InnerBannerBg";
import {PageAnimation} from "../../components/animations/PageAnimation";
import {motion} from "framer-motion";


const data = [
    {
        "product_data": {
            "id": 3,
            "title": "Formonic Project",
            "slug": "formonic-project",
            "type": "2",
            "location": "Badda",
            "area": "Dhaka",
            "katha": "5.0 Khata",
            "storied": "G+7",
            "facing": "East",
            "latitude": "23.78535050137822",
            "longitude": "90.4252152158947",
            "category_title": "Completed",
            "type_title": "Commercial"
        }
    },
]

const MyComponent = ({offset}) => {
    const dispatch = useDispatch()
    const getData = useSelector(store => store.projects)
    let {slug} = useParams();

    useEffect(() => {
        let api_url = apiEndPoints.PROJECTS_DATA;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
            [ApiParam.VALUE]: slug,
        };

        dispatch(fetchProjectsDetail([api_url, api_params]));
    }, []);

    const banner = getData?.detail?.data
    const desktop = getData?.detail?.data?.images?.list?.find((f) => f?.Desktop === "on");
    const mobile = getData?.detail?.data?.images?.list?.find((f) => f?.Mobile === "on");
    const overview = getData?.detail?.data?.posts?.list?.find((f) => f?.data?.slug === "overview");
    const atAGlance = getData?.detail?.data?.posts?.list?.find((f) => f?.data?.slug === "at-a-glance");
    const features = getData?.detail?.data?.posts?.list?.find((f) => f?.data?.slug === "features-amenities");
    const gallery = getData?.detail?.data?.posts?.list?.find((f) => f?.data?.slug === "gallery");
    const video = getData?.detail?.data?.posts?.list?.find((f) => f?.data?.slug === "video");
    const location = getData?.detail?.data?.posts?.list?.find((f) => f?.data?.slug === "location");


    // console.log('getData --- ', getData?.detail?.data?.product_data?.title)

    const data = [
        {
            "product_data": {
                "id": 0,
                "title": banner?.product_data?.title,
                "slug": banner?.product_data?.slug,
                "type": banner?.product_data?.type,
                "location": banner?.product_data?.location,
                "latitude": banner?.product_data?.subtitle?.toString(),
                "longitude": banner?.product_data?.short_desc?.toString(),
            }
        },
    ]

    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Project | Tropical Homes </title>
                <meta name="description"
                      content="Our legacy as a distinguished real estate firm in our nation spans generations, cementing our reputation as a paragon of experience and reliability. Over the years, we’ve etched our name into the annals of real estate history, becoming a symbol of unwavering commitment to excellence."/>
            </Helmet>

            <motion.div key={`1pp83`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>

            <StyledComponent>
                <InnerBannerBg srcSm={mobile?.full_path} img={desktop?.full_path} title={banner?.product_data?.title}
                               location={banner?.product_data?.location}/>
                {
                    overview && <Overview title={overview?.data?.title}
                                          descriptionLeft={overview?.data?.subtitle}
                                          descriptionRight={overview?.data?.description}/>
                }
                {
                    atAGlance && <AtaGlance data={atAGlance} offset={offset}/>
                }
                {features && <FeatureSlider data={features}/>}
                {gallery && <Gallery data={gallery}/>}
                {video && <Video data={video}/>}
                <Location gallery={gallery} detail data={data} sectionData={location} line/>
                <EnquiryForm project={getData?.detail?.data?.product_data?.title}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  .show-in-mobile {
    padding: 0;
  }

  .about-global {
    padding-top: 80px;
  }
`;

export default MyComponent;
