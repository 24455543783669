import React, {useEffect} from 'react';
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import Details from "../../components/news/Details";
import {fetchBlogDetail} from "../../api/redux/blog";
import {PageAnimation} from "../../components/animations/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = () => {
    const dispatch = useDispatch()
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: slug,
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchBlogDetail([api_url, api_params]));
    }, []);
    // const router = useRouter();
    const getData = useSelector(store => store.blog)

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Blog | Tropical Homes </title>
                <meta name="description"
                      content="Our legacy as a distinguished real estate firm in our nation spans generations, cementing our reputation as a paragon of experience and reliability. Over the years, we’ve etched our name into the annals of real estate history, becoming a symbol of unwavering commitment to excellence."/>
            </Helmet>

            <motion.div key={`1s83`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>
            <StyledComponent>
                <Details data={getData}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
