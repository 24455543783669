import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {ApiParam} from "../../api/network/apiParams";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import GoProject from "../../components/home/GoProject";
import About from "../../components/home/About";
import BigBanner from "../../components/home/BigBanner";
import {fetchHome} from "../../api/redux/home";
import FeatureSlider from "../../components/home/FeatureSlider";
import BlogSlider from "../../components/home/BlogSlider";
import WhyTropical from "../../components/home/WhyTropical";
import Partners from "../../components/home/Partners";
import Testimonial from "../../components/Testimonial";
import GoogleMapsMarkers from "../../components/home/GoogleMapsMarkers";
import SisterConcern from "../../components/SisterConcern";
import Banner from "../../components/home/Banner";
import {hover} from "../../styles/globalStyleVars";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {PageAnimation} from "../../components/animations/PageAnimation";
import {motion} from "framer-motion";

const Home = ({offset}) => {
    const dispatch = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "home",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchHome([api_url, api_params]));
    }, []);

    const getData = useSelector((state) => state.home);


    const bannerData = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "banner")?.posts?.list;
    const exploreProjects = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "explore-project");
    const about = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "home-about");
    const commercialLandmark = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "commercial-landmarks");
    const projectsTitle = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "feature-projects");
    const featureProject = getData?.posts?.projects;
    const findAProjects = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "find-projects");
    const quotes = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "quote");
    const bestHome = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "why-tropical-homes-are-best");
    const partners = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "partner-with-developers");
    const map = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "map");
    const testimonials = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "testimonials");
    const strategicPartners = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "strategic-partners");
    const featureNews = getData?.posts?.newsEvents;

    const data = featureProject?.list?.map((item) => ({
        "product_data": {
            "id": item?.product_data?.id,
            "title": item?.product_data?.title,
            "slug": item?.product_data?.slug,
            "type": item?.product_data?.type,
            "location": item?.product_data?.location,
            "latitude": item?.product_data?.subtitle?.toString(),
            "longitude": item?.product_data?.short_desc?.toString(),
            "image": item?.images?.list
        }
    }));


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Tropical Homes | Leading Real Estate Developer</title>
                <meta name="description"
                      content="Our legacy as a distinguished real estate firm in our nation spans generations, cementing our reputation as a paragon of experience and reliability. Over the years, we’ve etched our name into the annals of real estate history, becoming a symbol of unwavering commitment to excellence."/>
            </Helmet>
            <motion.div key={`183`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>
            <StyledSection className={'home-page'}>
                <Banner data={bannerData}/>
                <GoProject data={exploreProjects}/>
                <About data={about}/>
                <BigBanner data={commercialLandmark}/>
                <div className="bgc-wrap">
                    <FeatureSlider Location={getData?.posts?.location} data={featureProject} findData={findAProjects}
                                   title={projectsTitle}/>
                    <WhyTropical data={bestHome}/>
                </div>
                <Partners data={partners}/>
                <GoogleMapsMarkers data={data} dataMap={map} offset={offset}/>


                {/*<Testimonial data={testimonials}/>*/}
                {/*<SisterConcern data={strategicPartners} bg={'#F9F9F9'}/>*/}
                <BlogSlider data={featureNews}/>

            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`
    //background: ${hover};

`;

export default Home;
