import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {ApiParam} from "../../api/network/apiParams";
import {fetchProjects} from "../../api/redux/projects";
import InnerBanner from "../../components/InnerBanner";
import ProjectList from "../../components/project/ProjectList";
import {useLocation, useHistory} from "react-router-dom";
import {PageAnimation} from "../../components/animations/PageAnimation";
import {motion} from "framer-motion";


const MyComponent = () => {
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const dispatch = useDispatch();
    const locationParams = useLocation();
    const location = useLocation()
    const history = useHistory()

    // push url after select options
    useEffect(() => {
        if (selectedStatus !== '' || selectedType !== '' || selectedLocation !== '')
            history.push(`/projects?status=${selectedStatus}&type=${selectedType}&location=${selectedLocation}`)
    }, [selectedStatus, selectedType, selectedLocation])

    // get the query param
    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECTS;

        let api_params = {
            [ApiParam.CATEGORY]: query.get('status') && query.get('status').toLowerCase(),
            [ApiParam.LOCATION]: query.get('location') && query.get('location').toLowerCase(),
            [ApiParam.TYPE]: query.get('type') && query.get('type').toLowerCase(),
            [ApiParam.IMAGE]: "yes",
        };
        dispatch(fetchProjects([api_url, api_params]));

    }, [query]);


    const getData = useSelector((state) => state.projects);

    const innerBanner = getData?.posts?.section_data;
    const desktop = innerBanner?.images?.list?.find((f) => f?.Desktop === "on");
    const mobile = innerBanner?.images?.list?.find((f) => f?.Mobile === "on");
    const projects = getData?.posts?.data;

    const locationOptions = projects?.map(item => {
        return {
            value: item?.product_data?.location,
            label: item?.product_data?.location
        };
    });


    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Projects | Tropical Homes </title>
                <meta name="description"
                      content="Our legacy as a distinguished real estate firm in our nation spans generations, cementing our reputation as a paragon of experience and reliability. Over the years, we’ve etched our name into the annals of real estate history, becoming a symbol of unwavering commitment to excellence."/>
            </Helmet>

            <motion.div key={`1mm83`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>

            <StyledComponent>
                <InnerBanner title={innerBanner?.page_data?.subtitle} img={desktop?.full_path}
                             srcSm={mobile?.full_path}/>
                <ProjectList location={locationOptions} selectedStatus={selectedStatus}
                             setSelectedStatus={setSelectedStatus}
                             selectedType={selectedType} setSelectedType={setSelectedType}
                             selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation}
                             data={projects}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
