import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Button from "../components/Button";
import {title} from "../styles/globalStyleVars";
import InnerBanner from "../components/InnerBanner";
import MissionVission from "../components/about/MissionVission";
import Team from "../components/about/Team";
import GoProject from "../components/home/GoProject";
import About from "../components/home/About";
import BigBanner from "../components/home/BigBanner";
import FeatureSlider from "../components/home/FeatureSlider";
import BlogSlider from "../components/home/BlogSlider";
import WhyTropical from "../components/home/WhyTropical";
import Partners from "../components/home/Partners";
import GoogleMapsMarkers from "../components/home/GoogleMapsMarkers";
import Testimonial from "../components/Testimonial";
import SisterConcern from "../components/SisterConcern";
import Director from "../components/about/Director";
import ProjectList from "../components/project/ProjectList";

const MyComponent = ({offset}) => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Title</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <GoProject/>
                <About/>
                <BigBanner/>
                <div className="bgc-wrap">
                    <FeatureSlider/>
                    <WhyTropical/>
                </div>
                <Partners/>
                <GoogleMapsMarkers offset={offset}/>
                <Testimonial/>
                <SisterConcern bg={'#F9F9F9'}/>
                <BlogSlider/>
                <InnerBanner img={'/images/dynamic/about-banner.jpg'} title={'About Us'}/>
                <MissionVission/>
                <Team/>
                <Director/>
                <SisterConcern bg={'#F9F9F9'}/>
                <ProjectList/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
