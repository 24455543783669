import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Select, {components} from "react-select";
import ProjectSingle from "../ProjectSingle";
import Button from "../Button";
import {useLocation} from "react-router-dom";
import router from "react-router-dom/es/Router";

const MyComponent = ({
                         data,
                         selectedStatus,
                         setSelectedStatus,
                         selectedType,
                         setSelectedType,
                         selectedLocation,
                         setSelectedLocation,
                         location,
                         handleStatusSelection
                     }) => {

    const [visibleItems, setVisibleItems] = useState(12);

    const handleLoadMore = () => {
        // Increase the number of visible items by 6 when the "Load More" button is clicked
        setVisibleItems(prevVisibleItems => prevVisibleItems + 9);
    };


    // dropdown style
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#00A651' : '#]212158',
            margin: 0,
            cursor: 'pointer'
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer'
        }),
    };

    // drop down indecator
    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ?
                        <img src="/images/static/caret-up.svg" alt=""/>
                        :
                        <img src="/images/static/caret-down.svg" alt=""/>
                    }

                </components.DropdownIndicator>
            )
        );
    };


    // options
    const status = [
        {value: 'ready', label: 'Ready'},
        {value: 'ongoing', label: 'Ongoing'},
        {value: 'upcoming', label: 'Upcoming'},
        {value: 'completed', label: 'Completed'},
    ];
    const type = [
        {value: 'residential', label: 'Residential'},
        {value: 'commercial', label: 'Commercial'},
        {value: 'condominium ', label: 'Condominium '},
    ];


    // get the query param
    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const [hideProject, setHideProject] = useState(false)
    let query = useQuery();

    // hide completed projects
    useEffect(() => {
        // console.log('param', query.get('status'))
        if (query.get('status') === 'completed') {
            setHideProject(false)
        } else {
            setHideProject(true)
        }
    }, [query.get('status')])


    return (
        <StyledComponent className={'project-list'}>
            <Container>
                <Row className={'project-list__filter'}>
                    <Col md={4} sm={6}>
                        <p>Status</p>
                        <Select components={{DropdownIndicator}}
                                styles={customStyles}
                                classNamePrefix={'custom'} className='select-here '
                                placeholder={query.get('status') ? query.get('status') : 'Project Status'}
                                options={status}
                                onChange={(selectedOption) => setSelectedStatus(selectedOption.value)}
                        />
                    </Col>
                    <Col md={4} sm={6}>
                        <p>Type</p>
                        <Select components={{DropdownIndicator}}
                                styles={customStyles}
                                classNamePrefix={'custom'} className='select-here '
                                placeholder={query.get('type') ? query.get('type') : 'Project Type'}
                                options={type}
                            // value={selectedType}
                                onChange={(selectedOption) => setSelectedType(selectedOption.value)}
                        />
                    </Col>
                    <Col md={4} sm={6}>
                        <p>Location</p>
                        <Select components={{DropdownIndicator}}
                                styles={customStyles}
                                classNamePrefix={'custom'} className='select-here '
                                placeholder={query.get('location') ? query.get('location') : 'Project Location'}
                                options={location}
                            // defaultValue={selectedLocation}
                                onChange={(selectedOption) => setSelectedLocation(selectedOption.value)}
                        />
                    </Col>
                </Row>
            </Container>

            <div className="project-list__wrap">
                <Container>
                    <Row>
                        {
                            // data?.slice(0, v).map((item) => {
                            data?.map((item) => {
                                const thumb = item?.images?.list.find((f) => f?.thumb === "on")
                                return (
                                    <Col
                                        className={hideProject && item?.product_data?.category_id === 'completed' ? 'no-need' : 'yeeeee'}
                                        md={4} sm={6}>

                                        {/*<p style={{color: 'red'}}>{item?.product_data?.category_id}</p>*/}

                                        <ProjectSingle
                                            catId={item?.product_data?.category_id}
                                            slug={item?.product_data?.slug}
                                            address={item?.product_data?.location}
                                            title={item?.product_data?.title}
                                            img={thumb?.full_path ? thumb?.full_path : '/images/dynamic/project1.jpg'}/>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    {/*{*/}
                    {/*    <div className="load-more">*/}
                    {/*        <Button handleLoadMore={handleLoadMore} margin={'auto'} color={'#FFF'} text={'Load More'}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*}*/}
                </Container>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 60px;
  padding-bottom: 150px;
  background-color: #1D3130;
  //background-image: url('/images/static/mission-bg.png');
  //background-blend-mode: multiply;
  position: relative;
  overflow: hidden;
  //load more button
  .load-more {
    padding-top: 60px;
  }

  .project-list__filter {
    padding-bottom: 40px;

    p {
      font-size: 12px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 10px;
    }
  }

  //caret
  .custom__control {
    background-color: transparent;
    border: 1px solid #D0DEDE !important;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    //margin-bottom: 60px;
    margin-bottom: 20px;
    border-radius: 50px;
    height: 50px;
    padding: 0 30px;

    svg line {
      stroke: #FFF
    }

    .custom__single-value {
      color: #FFF;
      font-size: 15px;
      line-height: 20px;
    }

    .custom__placeholder {
      text-transform: capitalize;
      color: #FFF;
      font-size: 16px;
      line-height: 24px;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {

    }
  }

  .custom__menu {
    z-index: 9;
  }

  .css-t3ipsp-control:hover {
    border-color: rgba(255, 255, 255, 0.46);
  }

  .custom__indicator-separator {
    display: none;
  }

  .custom__indicator {
    padding-right: 0;
  }

  .project-list__wrap {
    padding-top: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    min-height: 70vh;

    .col-sm-6 {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 768px) {
    //load more button
    .load-more {
      padding-top: 40px;
    }
  }
`;

export default MyComponent;
