import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchBlog} from "../../api/redux/blog";
import InnerBanner from "../../components/InnerBanner";
import BlogNewsDetail from "../../components/BlogNewsDetail";
import {fetchNews} from "../../api/redux/news";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/animations/PageAnimation";


const MyComponent = () => {
    const dispatch = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CHILD_PAGE;

        let api_params = {
            [ApiParam.PAGE_ID]: "45",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
            [ApiParam.FILE]: "no",
            [ApiParam.GALLERY]: "no",
        };

        dispatch(fetchBlog([api_url, api_params]));
    }, []);

    const getData = useSelector((state) => state.blog);
    const blog = getData?.posts?.data?.list;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Blog | Tropical Homes </title>
                <meta name="description"
                      content="Our legacy as a distinguished real estate firm in our nation spans generations, cementing our reputation as a paragon of experience and reliability. Over the years, we’ve etched our name into the annals of real estate history, becoming a symbol of unwavering commitment to excellence."/>
            </Helmet>

            <motion.div key={`13`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>

            <StyledComponent>
                <InnerBanner title={'Blogs'} img={'/images/dynamic/news.jpg'}
                             srcSm={'/images/dynamic/news.jpg'}/>
                <BlogNewsDetail data={blog}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
