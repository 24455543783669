import {Route, Switch, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import {SplitText} from "gsap/SplitText";

// page imports
import About from './pages/about';
import Components from './pages/components';
import Home from './pages/home';
import Project from './pages/project';
import ProjectDetail from './pages/project/single';
import Blog from './pages/blog';
import BlogSingle from './pages/blog/single';
import News from './pages/news';
import NewsDetail from './pages/news/single';
import Contact from './pages/contact';
import Career from './pages/career';
import Landowners from './pages/landowners';
import Customers from './pages/customers';
import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import PageTransition from "./components/animations/PageTransition";
import Footer from "./components/Footer";
import {ToastContainer} from "react-toastify";
import Menu from "./components/Menu";
import FooterForm from "./components/FooterForm";
import TopArrow from "./components/TopArrow";
import Social from "./components/Social";
import 'react-toastify/dist/ReactToastify.css';
import {Loading} from "./components/Loading";
import {useSelector} from "react-redux";
import globalReducer from "./api/redux/global";
import router from "react-router-dom/es/Router";
import {AnimatePresence} from "framer-motion";
import FloatingIcon from "./components/FloatingIcon";

function App() {
    const [offset, setOffset] = useState('90');
    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    const store = useSelector(s => s)

    useEffect(() => {
        const updateOffset = () => {
            if (window.innerWidth > 767) {
                const container = document.querySelector('.container');
                if (container) {
                    setOffset(container.offsetLeft + 15);
                }
            }
        };

        // Add an event listener for the DOMContentLoaded event
        document.addEventListener('DOMContentLoaded', updateOffset);

        // Add event listener to update offset on window resize
        window.addEventListener('resize', updateOffset);
        window.addEventListener('load', updateOffset);

        return () => {
            document.removeEventListener('DOMContentLoaded', updateOffset);
            window.removeEventListener('resize', updateOffset);
            window.removeEventListener('load', updateOffset);
        };
    }, []);

    // smooth scroll init
    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
            // effects: true // looks for data-speed and data-lag attributes on elements
            // speed: 2
        });
        ScrollTrigger.refresh();

        if (location.hash) {
            setTimeout(() => {
                smoother.scrollTo(location.hash, 3)
            }, 500)
        }

        // return () => {
        //     smoother.kill();
        //     // smoother.revert();
        // };
    }, [location, location.pathname, location.hash, store.about]);

    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page')
        } else {
            document.body.classList.remove('in-home-page')
        }

        window.scroll(0, 0)

        const footerForm = document.querySelector('.footer-form');
        if (footerForm) {
            if (location.pathname === '/projects') {
                footerForm.classList.add('bg-height');
            } else {
                footerForm.classList.remove('bg-height');
            }
        }

        if (location.pathname.startsWith('/blog/')) {
            document.body.classList.add('on-blog')
        } else {
            document.body.classList.remove('on-blog')
        }
    }, [location.pathname])


    // animation functions
    // ImageReveal()
    // Parallax()
    ParallaxImg()
    SplitUp()

    // page transition
    // PageTransition()

    const [showFooterForm, setShowFooterForm] = useState(true)


    // footer form show & hide
    useEffect(() => {
        if (location.pathname.startsWith('/projects/')) {
            setShowFooterForm(false)
        } else if (location.pathname === '/landowners') {
            setShowFooterForm(false)
        } else if (location.pathname === '/career') {
            setShowFooterForm(false)
        } else if (location.pathname.startsWith('/blog/')) {
            setShowFooterForm(false)
        } else if (location.pathname === '/contact') {
            setShowFooterForm(false)
        } else if (location.pathname === '/customers') {
            setShowFooterForm(false)
        } else {
            setShowFooterForm(true)
        }
    }, [location.pathname])


    return (
        <>
            {/*form submission loader*/}
            {store.globalReducer?.formLoading && <Loading/>}
            {store.projects?.formLoading && <Loading/>}
            {store.landowner?.formLoading && <Loading/>}
            {store.career?.formLoading && <Loading/>}
            {store.projects?.loading && <Loading/>}
            <GlobalStyle/>
            {/*page transition */}
            <ToastContainer
                position="top-right"
                autoClose={4000}
                closeOnClick
                hideProgressBar={true}
            />

            <Menu/>
            <TopArrow offset={offset}/>
            {/*{location.pathname === '/' && <Social/>}*/}
            {location.pathname === '/' && <FloatingIcon/>}

            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">
                    <AnimatePresence exitBeforeEnter>
                        <Switch location={location} key={'location.pathname'}>
                            <Route exact path="/">
                                <Home offset={offset}/>
                            </Route>
                            <Route exact path="/components">
                                <Components offset={offset}/>
                            </Route>
                            <Route exact path="/about" component={About}/>
                            <Route exact path={`/projects`} component={Project}/>
                            <Route exact path="/projects/:slug">
                                <ProjectDetail offset={offset}/>
                            </Route>
                            <Route exact path={`/blog`} component={Blog}/>
                            <Route exact path={`/blog/:slug`} component={BlogSingle}/>
                            <Route exact path={`/news`} component={News}/>
                            <Route exact path={`/news/:slug`} component={NewsDetail}/>
                            <Route exact path="/contact">
                                <Contact offset={offset}/>
                            </Route>
                            <Route exact path={`/career`} component={Career}/>
                            <Route exact path={`/landowners`} component={Landowners}/>
                            <Route exact path={`/customers`} component={Customers}/>
                            <Route component={Error}/>
                        </Switch>
                    </AnimatePresence>
                    {showFooterForm && <FooterForm/>}
                    <Footer/>
                </div>

            </div>

        </>


    );
}

export default App;
