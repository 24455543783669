import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchCareer} from "../../api/redux/career";
import {fetchContact} from "../../api/redux/contact";
import InnerBanner from "../../components/InnerBanner";
import Address from "../../components/contact/Address";
import GetInTouch from "../../components/contact/GetInTouch";
import Location from "../../components/contact/Location";
import {components} from "react-select";
import {PageAnimation} from "../../components/animations/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = ({offset}) => {
    const dispatch = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "contacts",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchContact([api_url, api_params]));
    }, []);

    const getData = useSelector((state) => state.contact);
    const innerBanner = getData?.posts?.data;
    const address = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "address");
    const getIntouch = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "get-in-touch");
    const location = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "location");

    const desktop = innerBanner?.page_images?.list?.find((f) => f?.Desktop === "on");
    const mobile = innerBanner?.page_images?.list?.find((f) => f?.Mobile === "on");

    // console.log(location)

    const data = [
        {
            "product_data": {
                "id": 0,
                "title": location?.section_data?.subtitle,
                "location": location?.section_data?.description,
                "url": location?.section_data?.short_desc,
                "latitude": "23.78535050137822",
                "longitude": "90.4252152158947",
            }
        },
    ]

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Contact | Tropical Homes </title>
                <meta name="description"
                      content="Our legacy as a distinguished real estate firm in our nation spans generations, cementing our reputation as a paragon of experience and reliability. Over the years, we’ve etched our name into the annals of real estate history, becoming a symbol of unwavering commitment to excellence."/>
            </Helmet>

            <motion.div key={`1d83`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>

            <StyledComponent>
                <InnerBanner srcSm={mobile?.full_path} img={desktop?.full_path}
                             title={innerBanner?.page_data?.subtitle}/>
                <Address data={address} offset={offset}/>
                <GetInTouch data={getIntouch}/>
                <Location slide data={data}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
