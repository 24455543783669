import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchCareer} from "../../api/redux/career";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Overview";
import ApplyForm from "../../components/career/ApplyForm";
import {PageAnimation} from "../../components/animations/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = () => {
    const dispatch = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "career",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchCareer([api_url, api_params]));
    }, []);

    const getData = useSelector((state) => state.career);

    const innerBanner = getData?.posts?.data;
    const overview = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "overview");
    const applyForm = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "apply-form");

    const desktop = innerBanner?.page_images?.list?.find((f) => f?.Desktop === "on");
    const mobile = innerBanner?.page_images?.list?.find((f) => f?.Mobile === "on");

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Career | Tropical Homes </title>
                <meta name="description"
                      content="Our legacy as a distinguished real estate firm in our nation spans generations, cementing our reputation as a paragon of experience and reliability. Over the years, we’ve etched our name into the annals of real estate history, becoming a symbol of unwavering commitment to excellence."/>
            </Helmet>

            <motion.div key={`18a3`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>

            <StyledComponent>
                <InnerBanner srcSm={mobile?.full_path} img={desktop?.full_path} title={innerBanner?.page_data?.title}/>
                <Overview title={overview?.section_data?.subtitle} descriptionLeft={overview?.section_data?.description}
                          descriptionRight={overview?.section_data?.short_desc}/>
                <ApplyForm data={applyForm}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
