import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import MissionVission from "../../components/about/MissionVission";
import Team from "../../components/about/Team";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchAbout} from "../../api/redux/about";
import Director from "../../components/about/Director";
import SisterConcern from "../../components/SisterConcern";
import About from "../../components/home/About";
import Counter from "../../components/about/Counter";
import {PageAnimation} from "../../components/animations/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = () => {
    const dispatch = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "about",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchAbout([api_url, api_params]));
    }, []);

    const getData = useSelector((state) => state.about);

    const innerBanner = getData?.posts?.data;
    const ourStory = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "our-story");
    const missionVision = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "mission-vision-value");
    const managementMessages = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "management-messages");
    const boardOfDirectors = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "board-of-director");
    const sisterConcern = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "sister-concern");


    const desktop = innerBanner?.page_images?.list?.find((f) => f?.Desktop === "on");
    const mobile = innerBanner?.page_images?.list?.find((f) => f?.Mobile === "on");

    // useEffect(() => {
    //     console.log('window', window.innerHeight)
    // }, [])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About | Tropical Homes</title>
                <meta name="description"
                      content="Our legacy as a distinguished real estate firm in our nation spans generations, cementing our reputation as a paragon of experience and reliability. Over the years, we’ve etched our name into the annals of real estate history, becoming a symbol of unwavering commitment to excellence."/>
            </Helmet>

            <motion.div key={`1w83`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>

            <StyledComponent>
                <InnerBanner srcSm={mobile?.full_path} img={desktop?.full_path} title={innerBanner?.page_data?.title}/>
                <Counter id={'our-story'} data={ourStory}/>
                <MissionVission id1={'vision'} id2={'mission'} id3={'values'} data={missionVision}/>
                <Team id={'message-from-chairman'} id2={'message-from-MD'} data={managementMessages}/>
                <Director id={'board-of-directors'} data={boardOfDirectors}/>
                <SisterConcern data={sisterConcern} bg={'#F9F9F9'}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
