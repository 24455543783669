import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Img} from "../Img";
import Button from "../ButtonSubmit";
import {components} from "react-select";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/career";


const CustomDropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <rect id="Rectangle_5620" data-name="Rectangle 5620" width="20" height="20" fill="#fff" opacity="0"/>
        </svg>
    </components.DropdownIndicator>
);
const ApplyForm = ({data}) => {
    const [selectedFileName, setSelectedFileName] = useState("");
    const [cv, setCv] = useState(null);
    const cvRef = useRef();

    const handleInputcv = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFileName(file.name);
            setCv(file); // Update the cv state with the selected file
        }
    };
    const store = useSelector(s => s.career)

    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });


    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.FORM_SUBMIT

    const [Facing, setFacing] = useState('')
    const [Type, setType] = useState('')
    const handleFacing = (e) => {
        setFacing(e)
    }
    const handleType = (e) => {
        setType(e)
    }

    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('form_id', 'career-form');
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.phone);
        formData.append('message', e?.message);
        dispatch(postForm([apiEndPoint, formData]))
    }

    useEffect(() => {
        if (!store?.formLoading) {
            if (isSubmitSuccessful) {
                success('Thank you for your message')
                reset()
            }
        }
    }, [isSubmitSuccessful, store])


    return (
        <StyledComponent>
            <div className="form-area">
                <div className="form-area__bg">
                    <Img src={'/images/dynamic/apply-bg.jpg'}/>
                </div>
                <div className="form-area__form">
                    <Container>
                        <Row>
                            <Col sm={{span: 9, offset: 1}} className={'text-center'}>
                                <div className="form-area__form__title">
                                    {data?.section_data?.subtitle ?
                                        <h2 className={'split-up'}>{data?.section_data?.subtitle}</h2> :
                                        <h2 className={'split-up'}>{'Apply Now'}</h2>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={{span: 10, offset: 1}}>
                                <Form className={'form'}>
                                    <Row>
                                        <Col className={'form__item'} md={6} xs={12}>
                                            <Form.Label>Name*</Form.Label>
                                            <Form.Control
                                                className={errors?.name?.message ? 'has-error' : ''} {...register('name', {
                                                required: {
                                                    value: true,
                                                    message: "Please enter your full name",
                                                }

                                            })} type="text" placeholder="Enter your name"/>
                                        </Col>
                                        <Col className={'form__item'} md={6} xs={12}>
                                            <Form.Label>Phone Number*</Form.Label>
                                            <Form.Control
                                                className={errors?.phone?.message ? 'has-error' : ''}  {...register("phone", {
                                                required: {
                                                    value: true,
                                                    message: "Enter your valid phone number",
                                                },
                                                pattern: {
                                                    value: /^01[0-9]{9}$/,
                                                    message: "Enter a valid 11 digit phone number",
                                                },
                                            })} type="number"
                                                placeholder="Enter your phone number"/>
                                        </Col>
                                        <Col className={'form__item'} md={6} xs={12}>
                                            <Form.Label>Email*</Form.Label>
                                            <Form.Control
                                                className={errors?.email?.message ? 'has-error' : ''} {...register('email', {

                                                pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: "Please enter a valid email address"
                                                }
                                            })} type="email"
                                                placeholder="Enter your email address"/>
                                        </Col>
                                        <Col className={'form__item'} md={6} xs={12}>
                                            <Form.Label>CV*</Form.Label>
                                            <div className="attachCvName">
                                                <div className="attach-cv">
                                                    <Form.Control
                                                        ref={cvRef}
                                                        type="file"
                                                        accept=".pdf"
                                                        id="resume-upload"
                                                        style={{display: "none"}}
                                                        onChange={handleInputcv}
                                                        {...register('cv')}
                                                    />
                                                    <Form.Label
                                                        htmlFor="resume-upload"
                                                        className="resume-upload-label"
                                                        style={{
                                                            display: selectedFileName ? "none" : "block",
                                                        }}
                                                    >
                                                        Upload CV
                                                    </Form.Label>
                                                    {selectedFileName && (
                                                        <div className="file-name">
                                                            {selectedFileName.length > 10 ? (
                                                                <>
                                                                    {selectedFileName.substring(0, 10)}...
                                                                    {selectedFileName.split(".").pop()}
                                                                </>
                                                            ) : (
                                                                selectedFileName
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <span>Max 2MB</span>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Label>Cover Letter*</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={6} // This sets the number of visible rows, adjust as needed
                                                placeholder="Message"
                                                {...register('message')}
                                            />
                                        </Col>
                                        <Col className={'form__item'} md={6} xs={12}>
                                            <div className="button" onClick={handleSubmit(handleFormSubmit)}>
                                                <Button width={'100%'} fontSize={'16'}
                                                        text={'Submit Message'}
                                                        color={'#FFFFFF'} background={'#00A651'}
                                                        hoverBackground={'#1D3130'}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;

  .dc-btn {
    width: unset;
  }

  textarea {
    background: transparent !important;
    color: #FFF !important;
  }


`;

export default ApplyForm;
